//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import { i18n } from '@/i18n';
const { fields } = UserModel;

export default {
  name: 'app-forgot-password-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
      },
      model: {},
      isTyping: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loadingPasswordResetEmail',
    }),
    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      doSendPasswordResetEmail: 'auth/doSendPasswordResetEmail',
    }),
    i18n(code) {
      return i18n(code);
    },
    onInputChange() {
      this.model.email
        ? (this.isTyping = true)
        : (this.isTyping = false);
    },
    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }
      await this.doSendPasswordResetEmail(this.model.email);
    },
  },
};
